'use strict';

var recommendationCarousel = '.recs-filter-js';
/**
 * Validates and Return the CQuotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
    var einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * fills in the carousel with product tile html objects
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} $parentElement parent element where recommendations will show.
 */
function fillDomElement(einsteinResponse, $parentElement) {
    var recommender = $parentElement.data('recommender');
    var recommendedProducts = einsteinResponse[recommender].recs;
    var mockProductIds = $parentElement.data('mock-products') ? $parentElement.data('mock-products') : null;

    // If mock product ids are provided, use them instead of the einstein recommended products
    if (mockProductIds && mockProductIds.length > 0) {
        recommendedProducts = mockProductIds.split(',').map(function (mockProductId) {
            var mockProduct = {
                id: ''
            };
            mockProduct.id = mockProductId;
            return mockProduct;
        });
    }

    if (recommendedProducts && recommendedProducts.length > 0) {
        var components = [];
        components = recommendedProducts.map(function (recommendedProduct) {
            var tiledefinition = {};
            tiledefinition.model = {
                type: 'product',
                id: recommendedProduct.id
            };
            return tiledefinition;
        });

        var url = new URL($parentElement.data('product-load-url'));
        url.searchParams.append('components', JSON.stringify(components));
        url.searchParams.append('limit', $parentElement.data('limit'));
        url.searchParams.append('recommender', recommender);
        url.searchParams.append('color', $parentElement.data('color'));
        $.ajax({
            url: url.href,
            type: 'get',
            dataType: 'html',
            success: function (html) {
                $parentElement.find('.carousel-inner').html(html);
                $('body').trigger('einstien-carousel:setup', {});
            },
            error: function () {
            }
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile($parentElement, einsteinUtils, anchorsArray) {
    var recommender = $parentElement.data('recommender');

    var params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    /**
    * Processes a recommendation responses
    * @param {Object} einsteinResponse CQuotient object
    */
    function recommendationsReceived(einsteinResponse) {
        fillDomElement(einsteinResponse, $parentElement);
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
        einsteinUtils.widgets.push({
            recommenderName: recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized product specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createProductAnchor($parentElement) {
    return [{
        id: $parentElement.data('primaryProductId'),
        sku: $parentElement.data('secondaryProductId'),
        type: $parentElement.data('alternativeGroupType'),
        alt_id: $parentElement.data('alternativeGroupId')
    }];
}

/**
 * Retrieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createCategoryAnchor($parentElement) {
    return [{ id: $parentElement.data('categoryId') }];
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadRecommendations() {
    var einsteinUtils = getEinsteinUtils();
    if (einsteinUtils) {
        var $recommendationTiles = $('.einstein-carousel:not([data-scripted])');
        $recommendationTiles.each(function () {
            var $parentElement = $(this);
            var typeID = $parentElement.data('typeId');
            $parentElement.attr('data-scripted', 'true');
            if ($parentElement.closest('.experience-einstein-einsteinRecommenderCarouselProduct').length) {
                return processRecommendationsTile($parentElement, einsteinUtils, createProductAnchor($parentElement));
            }
            if ($parentElement.closest('.experience-einstein-einsteinRecommenderCarouselCategory').length) {
                return processRecommendationsTile($parentElement, einsteinUtils, createCategoryAnchor($parentElement));
            }
            if (typeID === 'einstein.einsteinRecommenderCarouselProduct') {
                return processRecommendationsTile($parentElement, einsteinUtils, createProductAnchor($parentElement));
            }
            if (typeID === 'einstein.einsteinRecommenderCarouselCategory') {
                return processRecommendationsTile($parentElement, einsteinUtils, createCategoryAnchor($parentElement));
            }
            return processRecommendationsTile($parentElement, einsteinUtils);
        });
    }
}
/**
 * Method to get the filtered recommended product ids
 * @param {*} carousels placeholder for carousel div elements
 * @returns {*} list of product ids
 */
function getFilteredRecommendationProductIds(carousels) {
    var pids;
    var recommendationValues = [];
    for (var ij = 0; ij < carousels.length; ij += 1) {
        var value = $(carousels[ij]).attr('data-filtered-recommendations');
        if (value && value !== 'null') {
            value = Object.keys(JSON.parse(value));
            recommendationValues.push(value);
        }
    }

    var mergedValues;
    for (var li = 0; li < recommendationValues.length; li += 1) {
        mergedValues = !mergedValues ? recommendationValues[li] : mergedValues.concat(recommendationValues[li]);
    }
    if (recommendationValues.length > 1) {
        var uniqueValues = mergedValues.filter((curvalue, index) => mergedValues.indexOf(curvalue) === index);
        mergedValues = uniqueValues;
    }
    if (mergedValues) {
        pids = mergedValues.join('|');
    }
    return pids;
}
/**
 * Method to get the recommended product ids
 * @param {*} carousels placeholder for carousel div elements
 * @returns {*} list of product ids
 */
function getRecommendedProductIds(carousels) {
    var pids;
    pids = getFilteredRecommendationProductIds(carousels);
    if (!pids) {
        var pid;
        var ids = carousels.find('.image-container .product');
        for (var i = 0; i < ids.length; i += 1) {
            pid = $(ids[i]).data('pid');
            if (pid) {
                if (!pids) {
                    pids = pid;
                } else if (pids.indexOf(pid) < 0) {
                    pids += '|' + pid;
                }
            }
        }
    }
    return pids;
}
/**
 * Method to hide a product tile in carousel if product is unavailable.
 * @param {*} ids placeholder for collection of dom elements.
 * @param {*} recommendations placeholder for recommendations and their display status.
 */
function hideUnavailableProducts(ids, recommendations) {
    if (recommendations) {
        var pid;
        for (var j = 0; j < ids.length; j += 1) {
            var $currentElement = $(ids[j]);
            pid = $currentElement.data('pid');
            var $parentElement = $currentElement.closest('.product-tile').parent().closest('.slick-slide');
            if ($parentElement && !$parentElement.length) {
                $parentElement = $currentElement.closest('.product-tile').parent();
            }
            if (recommendations[pid]) {
                $parentElement.addClass('show-tile');
            } else {
                $parentElement.removeClass('show-tile');
            }
        }
    }
}
/**
 * Method to add recommendation data in the data attribute
 * @param {jQuery} elements placeholder for div element
 * @param {Object} recommendations placeholder for json data
 */
function addFilterRecommendationJson(elements, recommendations) {
    if (elements && elements.length && recommendations) {
        var recommendationStr = JSON.stringify(recommendations);
        for (var i = 0; i < elements.length; i += 1) {
            var currentDiv = $(elements[i]);
            currentDiv.attr('data-filtered-recommendations', recommendationStr);
        }
    }
}
/**
 * Method to create cookie
 * @param {*} params placeholder for params
 */
function createCookie(params) {
    if (params) {
        document.cookie = 'filterRecommendations=' + params + ';Max-Age=2592000;path=/';
    }
}
/**
 * Method to toggle slick filter or unfilter
 * @param {*} $carousels placeholder for carousels.
 * @param {boolean} isFilter placeholder for isUnfilter
 */
function controlSlick($carousels, isFilter) {
    if ($carousels && $carousels.length) {
        $carousels.each(function (ij) {
            var $carousel = $($carousels[ij]);
            var $currentCarousel = $carousel.hasClass('product-carousel') ? $carousel : $carousel.find('.product-carousel');
            if ($currentCarousel && $currentCarousel.hasClass('slick-initialized')) {
                if (isFilter) {
                    $currentCarousel.slick('slickFilter', '.show-tile');
                } else {
                    $currentCarousel.slick('slickUnfilter');
                }
            }
        });
    }
}

/**
 * Method to initiate filterRecommendation and expose it to window object
 */
function filterRecommendations() {
    $(document).on('filterEinsteinRecommendations', function (e, params) {
        var $carousels = $(recommendationCarousel);
        createCookie(params);
        if ($carousels && $carousels.length) {
            var pids;
            pids = getRecommendedProductIds($carousels);
            var ajaxUrl = $carousels.data('url');
            if (params && pids && ajaxUrl) {
                ajaxUrl = ajaxUrl + '?' + params + '&pids=' + pids;
                $.ajax({
                    url: ajaxUrl,
                    method: 'GET',
                    success: function (response) {
                        if (response && response.recommendations) {
                            var recommendations = response.recommendations;
                            controlSlick($carousels, false);
                            var ids = $carousels.find('.image-container .product');
                            hideUnavailableProducts(ids, recommendations);
                            controlSlick($carousels, true);
                            addFilterRecommendationJson($carousels, recommendations);
                        }
                    },
                    error: function () {
                    }
                });
            }
        }
    });
}

$(document).ready(function () {
    loadRecommendations();
    document.addEventListener('content:updated', loadRecommendations);
    filterRecommendations();

    $(document).on('controlSlick', function () {
        var $carousels = $('.recs-filter-js');
        if ($carousels && $carousels.length) {
            controlSlick($carousels, false);
        }
    });
});

module.exports = {
    hideUnavailableProducts: hideUnavailableProducts
};
