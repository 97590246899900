/* eslint-disable sonarjs/cognitive-complexity */

'use strict';

var isPDP = $('[data-action="Product-Show"]').length > 0;
var isCart = $('[data-action="Cart-Show"]').length > 0;
var currentUUID;
var currentShipmentUUID = null;
const dataCurrentUUID = 'data-current-uuid';
const dataCurrentShipmentUUID = 'data-current-shipment-uuid';

// ID Selectors
const storeFinderModalID = '#storeFinderModal';

// Class Selectors
const storeLocatorClass = '.store-locator';
const searchStoreCoordinatesClass = '.js-search-store-coordinates';
const searchStoreZipClass = '.js-search-store-zip';
const btnSelectStoreClass = '.btn-select-store:not(".my-account-preferences")';
const btnStoreLocatorSearchClass = '.btn-storelocator-search';
const inStoreInventoryDialogClass = '.in-store-inventory-dialog';
const storeFinderClass = '.js-store-finder';

// Results Selectors
const inStoreLocatorNoResultsClass = '.store-locator-no-results';
const inStoreLocatorGeoLocationDenied = '.store-locator-geolocation-denied';
const storeLocatorResultsClass = '.results.striped';
const resultsCard = '.results-card';

// Multi-class Selectors
const storeLocatorClasses = '.store-locator-container form.store-locator';

// Attribute Selectors
const dataSearchPidAttr = 'search-pid';
const dataSearchAtsAttr = 'search-ats';
const dataSearchRadiusAttr = 'search-radius';

// Data Selectors
const actionURLDataSelector = 'action-url';
const postalCodeFormSelector = '[name="postalCode"]';
const storeSelectPromptProductUUID = 'prompt-product-UUID';

/**
 * show resetStoreSearchFormResults modal from global header link
 */
function resetStoreSearchFormResults() {
    var $form = $(storeLocatorClasses);
    var $zipCode = $form.find(postalCodeFormSelector);
    $form.find(btnStoreLocatorSearchClass).attr(dataSearchPidAttr, '');
    $zipCode.val('').trigger('change');
    $(resultsCard).addClass('d-none');
    $('.results').empty().data({ 'has-results': '', radius: '', 'search-key': '' });
}

/**
 * Opens the store finder modal and resets the store search form results.
 * @param {boolean} promptedByProductUUID - Indicates if the user was prompted to select a store by a product UUID
 */
function openStoreFinderModal(promptedByProductUUID) {
    var $storeFinderModal = $(storeFinderModalID);

    resetStoreSearchFormResults();
    $storeFinderModal.data(storeSelectPromptProductUUID, promptedByProductUUID);
    $storeFinderModal.modal('show');
    $('#store-postal-code').trigger('focus');
}

/**
 * show store finder modal from global header link
 */
function showStoreFinder() {
    $(storeFinderClass).on('click', function () {
        if (isCart || isPDP) {
            resetStoreSearchFormResults();
        }
        $(storeFinderModalID).modal('show');
        $('#store-postal-code').trigger('focus');

        // used to distinguish from my account preferred store
        setTimeout(function () {
            $('.btn-select-store').removeClass('my-account-preferences');
        });
    });
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).filter(function (key) {
        return params[key] !== null;
    }).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');
    return newUrl;
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    var $resultsDiv = $('.results');
    $($resultsDiv).addClass('d-none');
    $(inStoreLocatorGeoLocationDenied).addClass('d-none');
    $(inStoreLocatorNoResultsClass).addClass('d-none');
    $(resultsCard).removeClass('d-none');

    if (!data) {
        return;
    }

    if (data.stores && data.stores.length > 0) {
        $resultsDiv.empty().data({ 'has-results': true, radius: data.radius, 'search-key': data.searchKey });

        if (data.storesResultsHtml) {
            $resultsDiv.append(data.storesResultsHtml);
        }
        $resultsDiv.removeClass('d-none');
    } else {
        if (data.noStoreResultMessagingHTML) {
            $(inStoreLocatorNoResultsClass).html(data.noStoreResultMessagingHTML);
        }
        $(inStoreLocatorNoResultsClass).removeClass('d-none');
    }
}

/**
 *  Displays geolocation denied message
 *
 * */
function showGeolocationDeniedMessage() {
    $(resultsCard).removeClass('d-none');
    $(inStoreLocatorNoResultsClass).addClass('d-none');
    $(storeLocatorResultsClass).addClass('d-none');
    $(inStoreLocatorGeoLocationDenied).removeClass('d-none');
}

/**
 * Handles geolocation errors by displaying appropriate messages based on the error code.
 *
 * @param {GeolocationPositionError} error - The error object containing the error code.
 */
function handleError(error) {
    // Display error based on the error code.
    const { code } = error;
    const { TIMEOUT, PERMISSION_DENIED, POSITION_UNAVAILABLE } = window.GeolocationPositionError;
    switch (code) {
        case TIMEOUT:
            // Handle timeout.
            console.error('Geolocation request timed out.');
            break;
        case PERMISSION_DENIED:
            // User denied the request.
            $('body').trigger('storeFinder:locationPermissionsDeny');
            console.error('User denied the request for Geolocation.');
            break;
        case POSITION_UNAVAILABLE:
            // Position not available.
            console.error('Position information is unavailable.');
            break;
        default:
            // Handle other errors.
            console.error('An unknown error occurred.');
            break;
    }
}

/**
 * Handles the click event for the near me button.
 * - If geolocation permissions are granted or prompt, it fetches the user's current position.
 * - Updates the form with the user's latitude and longitude.
 * - Serializes the form data and sends it via an AJAX request to the server.
 * - On successful response, updates the store locator results.
 */
function getUserLocationByCoordinates() {
    $('body').on('click', searchStoreCoordinatesClass, function (e) {
        e.preventDefault();
        var spinner = $(inStoreInventoryDialogClass).spinner();
        spinner.start();
        var $form = $(this).closest(storeLocatorClass);

        // Clear the postal code input
        $form.find('input[name="postalCode"]').val('');

        var radius = $('.results').attr(dataSearchRadiusAttr) ? parseInt($('.results').attr(dataSearchRadiusAttr), 10) : 50;
        var url = $form.attr('action');
        var urlParams = { radius: radius };
        if (isPDP || isCart) {
            var pid = $form.find(searchStoreCoordinatesClass).data(dataSearchPidAttr) || null;
            var ats = $form.find(searchStoreCoordinatesClass).data(dataSearchAtsAttr) || false;
            urlParams.products = pid;
            urlParams.ats = ats;
        }
        navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
            if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
                var userWillBePrompted = permissionStatus.state === 'prompt';

                navigator.geolocation.getCurrentPosition(function (position) {
                    // permission status changes after user accepts the prompt
                    if (userWillBePrompted && permissionStatus.state === 'granted') {
                        $('body').trigger('storeFinder:locationPermissionsAccept');
                    }

                    const { latitude, longitude } = position.coords;
                    urlParams.lat = latitude;
                    urlParams.long = longitude;
                    var payload = $form.is('form') ? $form.serialize() : { lat: latitude, long: longitude };
                    url = appendToUrl(url, urlParams);
                    $.ajax({
                        url: url,
                        type: $form.attr('method'),
                        data: payload,
                        dataType: 'json',
                        success: function (data) {
                            spinner.stop();
                            updateStoresResults(data);
                            $('.modal-dialog').addClass('stores-searched'); // set the modal to show that stores have been searched
                        }
                    }).fail(function () {
                        spinner.stop();
                    });
                }, function (error) {
                    spinner.stop();
                    handleError(error);
                    showGeolocationDeniedMessage();
                });
            } else {
                spinner.stop();
                showGeolocationDeniedMessage();
            }
        }).catch(function () {
            spinner.stop();
            showGeolocationDeniedMessage();
        });
    });
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function searchStore(element) {
    var dialog = element.closest(inStoreInventoryDialogClass);
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    var $form = element.closest(storeLocatorClass);
    var radius = $('.results').attr(dataSearchRadiusAttr) ? parseInt($('.results').attr(dataSearchRadiusAttr), 10) : 50;
    var url = $form.attr('action');
    var urlParams = { radius: radius };
    if (isPDP || isCart) {
        var pid = $form.find(searchStoreZipClass).data(dataSearchPidAttr) || null;
        var ats = $form.find(searchStoreZipClass).data(dataSearchAtsAttr) || false;
        urlParams.products = pid;
        urlParams.ats = ats;
    }
    var requestedPostalCode = $form.find(postalCodeFormSelector).val();
    var accountPage = $form.hasClass('my-account') ? 'true' : 'false';

    // validate form postal code
    var postalCodePattern = $form.find('input#store-postal-code').data('postalCodePattern');

    if (postalCodePattern) {
        var postalCodeRegEx = new RegExp(postalCodePattern);
        var isValidPostalCode = postalCodeRegEx.test(requestedPostalCode);

        if (isValidPostalCode) {
            $form.find('.form-control').removeClass('is-invalid');
            $form.find('.invalid-feedback').hide();
        } else {
            $form.find('.form-control').addClass('is-invalid');
            $form.find('.invalid-feedback').show();

            return;
        }
    }

    // Save requested Postal Code and update DOM elements with new data
    if (window.sitePrefs.bopisEnabled) {
        document.cookie = 'requestedPostalCode=' + requestedPostalCode + '; path=/';
        $('.change-store').each(function () {
            $(this).data('postal', requestedPostalCode);
        });
    }

    var payload = $form.is('form') ? $form.serialize() : { postalCode: requestedPostalCode };
    urlParams.accountPage = accountPage;

    url = appendToUrl(url, urlParams);

    spinner.start();

    $.ajax({
        url: url,
        type: $form.attr('method'),
        data: payload,
        dataType: 'json',
        success: function (data) {
            spinner.stop();
            updateStoresResults(data);
            $('.modal-dialog').addClass('stores-searched'); // set the modal to show that stores have been searched
        }
    }).fail(function () {
        spinner.stop();
    });
    return false;
}

/**
 * Trigger radius change event and update new store list
 *
 * NOTE: Unused due to CHO-562: radius selector removed and search radius
 * is always 50 units (mi/km). Preserved should the option to choose search
 * radius be restored.
 */
function changeRadius() {
    $('.store-locator-container .radius').on('change', function () {
        var radius = $(this).val();
        var searchKeys = $('.results').data('search-key');
        var currentPostalCode = $('#store-postal-code[name="postalCode"]').val();
        var url = $(this).data(actionURLDataSelector);
        var urlParams = {};

        urlParams = {
            radius: radius,
            postalCode: currentPostalCode,
            lat: searchKeys.lat,
            long: searchKeys.long
        };

        if (isPDP || isCart) {
            var pid = $(searchStoreCoordinatesClass).data('search-pid') || null;
            var ats = $(searchStoreCoordinatesClass).data('search-ats') || false;
            urlParams.products = pid;
            urlParams.ats = ats;
        }

        url = appendToUrl(url, urlParams);
        var dialog = $(this).closest(inStoreInventoryDialogClass);
        var spinner = dialog.length ? dialog.spinner() : $.spinner();
        spinner.start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                spinner.stop();
                updateStoresResults(data);
            }
        }).done(function () {
            spinner.stop();
        });
    });
}

/**
 * Updates the bopis store per line item on the cart
 * @param {string} url - route to update the store
 * @param {Object} data - object containing current line item data
 */
function updateStoreForLineItem(url, data) {
    $(inStoreInventoryDialogClass).spinner().start();
    $.ajax({
        url: appendToUrl(url, {
            storeId: data.storeID,
            productLineItemId: data.currentLineItemUUID
        }),
        type: 'get',
        dataType: 'json',
        success: function (res) {
            $('#inStoreInventoryModal').modal('hide');
            $('#inStoreInventoryModal').remove();

            if (isCart) {
                if (res.renderBopisTemplateHtml) {
                    $('#bopis-items').replaceWith(res.renderBopisTemplateHtml);
                } else if (res.updatedProductCard) {
                    $('.product-info.uuid-' + data.currentLineItemUUID).replaceWith(res.updatedProductCard);
                }
                setTimeout(function () {
                    $(storeFinderModalID).modal('hide');
                });

                $('body').trigger('storeFinder:bopisChangeStoreLink', res);
                $.spinner().stop();
            } else {
                /* @to-do: for now, the safest option is to just trigger a page reload if the user changes store on a line item within checkout, otherwise we'll need to figure out exactly what parts of the DOM / form we need to update with the updated shipment information */
                window.location.reload();
            }
        }
    });
}

/**
 * Search stores form submit event and button click event.
 */
function searchStoreEvent() {
    $(storeLocatorClasses).on('submit', function (e) {
        e.preventDefault();
        $('body').trigger('storeFinder:searchPostalCode');
        searchStore($(this));
    });
    $('.store-locator-container .btn-storelocator-search[type="button"]').on('click', function (e) {
        e.preventDefault();
        searchStore($(this));
    });
}

/**
 * Ajax call Select store on button click for Account-SetPreferredStore to store route with response
 */
function selectStore() {
    $('body').on('click', btnSelectStoreClass, function (e) {
        e.preventDefault();
        var $button = $(this);
        var spinner = $(inStoreInventoryDialogClass).spinner();
        var url = $button.data('href');
        var actionUrl = $button.data(actionURLDataSelector);
        var requestedStoreId = $button.data('store-id');
        var preferredStoreId = { preferredStoreId: requestedStoreId };
        var isLineItemStoreSelection = false;
        const isSoldOutProduct = $('#notify-cart-product').is(':visible');
        const isSoldOutMaster = $('#notify-cart-master').is(':visible');
        var storeData = {};

        if (isCart) {
            isLineItemStoreSelection = true;
        }

        if (isPDP || isCart) {
            var modal = $button.parents('.select-store-modal');
            var currentLineItemUUID = modal.attr(dataCurrentUUID) ? modal.attr(dataCurrentUUID) : null;
            currentShipmentUUID = modal.attr(dataCurrentShipmentUUID) ? modal.attr(dataCurrentShipmentUUID) : null;
            var selectedStore = $button;
            storeData = {
                storeID: selectedStore.data('store-id'),
                searchRadius: $('.results').data('radius'),
                searchPostalCode: $('.results').data('search-key').postalCode,
                storeDetailsHtml: selectedStore.siblings('label').html(),
                currentLineItemUUID: currentLineItemUUID,
                event: e
            };

            $('.js-emptyStore').removeClass('d-flex').addClass('d-none');
            $('.js-selected-store-details').removeClass('d-none');

            if (!isLineItemStoreSelection && isPDP) {
                $('body').trigger('store:selected', storeData);
            }
        }
        spinner.start();
        $.ajax({
            url: url,
            type: 'post',
            data: preferredStoreId,
            dataType: 'json',
            success: function (data) {
                if (!data.error) {
                    // Update selected store name in global header on desktop and side menu on mobile.
                    var selectedStoreName = data.customerPreferredStore.name;
                    var $selectedStoreCont = $('.js-selected-store');
                    var $selectedStore = $selectedStoreCont.find('.js-store-name');
                    var $selectStoreLabel = $('.js-select-store-label');
                    $selectedStoreCont.removeClass('d-none').addClass('d-inline-block');
                    $selectedStore.text(selectedStoreName).attr({ title: selectedStoreName, 'aria-label': selectedStoreName });
                    $selectStoreLabel.addClass('d-none').removeClass('d-inline-block');
                } else {
                    /* @to-do: handle error scenario to show error message if selected store is not saved in session. */
                }
                setTimeout(function () {
                    $(storeFinderModalID).modal('hide');
                });
                spinner.stop();
                // reload page only if product is OOS online to refresh PDP with store's stock
                if (isSoldOutProduct || isSoldOutMaster) {
                    window.location.reload();
                }

                if (isCart) {
                    // update store details on cart page
                    updateStoreForLineItem(actionUrl, storeData); // for cart pages

                    var $storeFinderModal = $(storeFinderModalID);
                    var promptProductUUID = $storeFinderModal.data(storeSelectPromptProductUUID);

                    // is user selected a store after being prompted to do so, continue updates for that product
                    if (promptProductUUID) {
                        $storeFinderModal.data(storeSelectPromptProductUUID, null);
                        $('body').trigger('cart:promptStoreSelected', [promptProductUUID, data.customerPreferredStore.ID]);
                    }
                }
            },
            error: function (err) {
                /* @to-do: handle error scenario to show error message if there is server side error */
                // eslint-disable-next-line no-console
                console.error('Error in setting preferred store: ' + err.message);
                spinner.stop();
            }
        });
    });
}

/**
* This function is to handle change store click event on PDP page.
*/
function changeStorePDPLink() {
    $('body').on('click', '.change-store', function () {
        $(storeFinderClass).trigger('click');
        var $this = $(this);
        var pid = !isPDP ? $this.data('pid') : $this.closest('.product-detail').attr('data-pid');
        var validateATS = $this.data('validate-ats');
        if (!isPDP && isCart) {
            currentUUID = $this.data('uuid');
            currentShipmentUUID = $this.data('shipment-uuid');
            var actionUrl = $this.data(actionURLDataSelector);
            var $form = $('.store-locator');
            $form.attr('action', actionUrl);
        }
        $(btnStoreLocatorSearchClass).attr(dataSearchPidAttr, pid);
        $(btnStoreLocatorSearchClass).attr(dataSearchAtsAttr, validateATS);
        var modal = $('.modal-body.select-store-modal');
        modal.attr(dataCurrentUUID, currentUUID).attr(dataCurrentShipmentUUID, currentShipmentUUID);
    });
}

/**
* This function is to initiate changing stores on the Cart page.
*/
function changeStoreInCart() {
    var promptedToSelectStoreByProductUUID = null;

    $('body').on('click', '.cart-change-store', function () {
        openStoreFinderModal(promptedToSelectStoreByProductUUID);
    });

    // user selected BOPIS fulfillment for a product but no preferred store is selected
    $('body').on('cart:promptStoreSelection', function (e, productUUID) {
        promptedToSelectStoreByProductUUID = productUUID;
        openStoreFinderModal(promptedToSelectStoreByProductUUID);
    });
}

/**
* This function will add necessary attributes to search modal for store finder w.r.t product.
*/
function initStoreParamsAndContent() {
    var pid = isPDP ? $('.product-detail').attr('data-pid') : false;
    $(btnStoreLocatorSearchClass).attr(dataSearchPidAttr, pid);

    // to hide store details on mobile for cart page
    if (isCart) {
        $(storeFinderClass).addClass('d-none').removeClass('d-flex');
    }
}

module.exports = {
    showStoreFinder: showStoreFinder,
    searchStoreEvent: searchStoreEvent,
    getUserLocationByCoordinates: getUserLocationByCoordinates,
    changeRadius: changeRadius,
    updateStoresResults: updateStoresResults,
    selectStore: selectStore,
    changeStorePDPLink: changeStorePDPLink,
    changeStoreInCart: changeStoreInCart,
    updateSelectStoreButton: function () {
        $('body').on('click', (function () {
            $(btnSelectStoreClass).prop('disabled', false);
        }));
    },
    initStoreParamsAndContent: initStoreParamsAndContent
};
