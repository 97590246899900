/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable require-jsdoc */

'use strict';

const debounce = require('../util/debounce');
const measures = require('../util/measures');

var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 0;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;
var ajaxProgress;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).closest('.input-wrapper').siblings('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('header').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('nb-modal');
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('');
    clearModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Searach suggestion wrapper in small desktop
 *
 * @param {Object|string} scope - DOM element, usually the input.search-field element
 */

function smallDesktop() { // eslint-disable-line require-jsdoc
    var isSmallDesktop = measures.isSmallDesktop();
    if (isSmallDesktop) {
        $('.suggestions').addClass('small-desktop');
    } else {
        $('.suggestions').removeClass('small-desktop');
    }
}

/**
 * Searach custom changes
 *
 * @param {Object|string} scope - DOM element, usually the input.search-field element
 */
function searchsuggetionstyles() {
    var $suggestions = $('.site-search .suggestions');
    var $productCustomizerWrapper = $('.product-customizer-wrapper');
    if ($suggestions.is(':visible') === true) {
        smallDesktop();
        if ($('#maincontent .seach-modal-bg').length === 0) {
            $(document).find('#maincontent').prepend('<div class="seach-modal-bg"></div>');
        }

        $suggestions.addClass('four-tile-width');
        if ($productCustomizerWrapper.length) {
            $('.header-fixed').removeClass('position-fixed');
        }
    } else {
        $(document).find('#maincontent .seach-modal-bg').detach();
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    if (!ajaxProgress) {
        var $suggestionsWrapper = getSuggestionsWrapper(this).empty();
        $.spinner().stop();
        var $document = $(document);

        if (typeof response !== 'object') {
            $suggestionsWrapper.append(response).show();
            // $(this).siblings('.reset-button').addClass('d-sm-block');
            $('input.search-field').attr('aria-describedby', 'search-assistive-text')
                .attr('aria-owns', 'search-results')
                .attr('aria-controls', 'search-results');
            positionSuggestions(this);

            if (isMobileSearch(this)) {
                toggleSuggestionsIcon('close');
                applyModals(this);
            }
            searchsuggetionstyles();

            $document.scrollTop(0);
            // Trigger screen reader by setting aria-describedby with the new suggestion message.
            var suggestionsList = $('.suggestions .item');
            if (suggestionsList.length) {
                $('input.search-field').attr('aria-describedby', 'search-result-count');
            } else {
                $('input.search-field').removeAttr('aria-describedby');
            }
            if (window.suggetionBoxDomReady) {
                window.suggetionBoxDomReady();
            }
        } else {
            $suggestionsWrapper.hide();
            $document.find('#maincontent .seach-modal-bg').detach();
        }
    }
}

/**
 * Converts a Unicode string (e.g., "U+2018") into the corresponding character.
 * The function removes the 'U+' prefix from the Unicode string and converts the rest of the string
 * (which represents a hexadecimal value) to an integer. Then, it uses `String.fromCharCode()`
 * to get the character corresponding to the given Unicode.
 * @param {string} unicode - The Unicode string (e.g., "U+2018") that needs to be converted to a character.
 * @returns {string} The character corresponding to the provided Unicode.
 * @example
 * const char = unicodeToChar("U+2018");
 * console.log(char); // Outputs: ‘
 */
function unicodeToChar(unicode) {
    return String.fromCharCode(parseInt(unicode.replace('U+', ''), 16));
}

/**
 * Replaces characters in the input string based on the provided replacements array in site preferences.
 * The replacements array contains objects with a `find` (Unicode to find) and `replace` (Unicode to replace with).
 * Each replacement is applied by converting Unicode values to characters and then replacing occurrences of those characters in the input string.
 * If no replacements are made, a message is logged to the console.
 * @param {string} inputString - The input string in which characters will be replaced.
 * @returns {string} The modified string after applying all replacements. If no replacements are made, the original string is returned.
 * @example
 * const replacements = [
 *     {search: "U+2018", replace: "U+0027"},
 *     {search: "U+2019", replace: "U+0027"}
 * ];
 * const inputString = "Hello‘World’";
 * const result = replaceUnicodeChars(inputString);
 * console.log(result); // "Hello'World'"
 */
function replaceUnicodeChars(inputString) {
    var searchPreferencesObj = window.sitePrefs ? window.sitePrefs.searchTermUpdateRules : null;
    if (!searchPreferencesObj) {
        return inputString;
    }
    let modifiedString = inputString;
    JSON.parse(searchPreferencesObj).forEach(function (pair) {
        let findChar = unicodeToChar(pair.search);
        let replaceChar = unicodeToChar(pair.replace);
        if (findChar && modifiedString.includes(findChar)) {
            modifiedString = modifiedString.split(findChar).join(replaceChar);
        }
    });
    return modifiedString;
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    var $scope = $(scope);
    if ($scope.val().length >= minChars) {
        var searchTerm = $scope.val().trim();
        searchTerm = replaceUnicodeChars(searchTerm);
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent(searchTerm),
            method: 'GET',
            success: processResponse,
            error: function () {
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        // $(scope).siblings('.reset-button').removeClass('d-sm-block');
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
    if ($scope.val().length >= 1) {
        $scope.closest('.input-wrapper').siblings('.reset-button').addClass('d-block');
    } else {
        $scope.closest('.input-wrapper').siblings('.reset-button').removeClass('d-block');
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            var $this = $(this);
            if ($this.hasClass('selected')) {
                $this.removeClass('selected');
                $this.removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $this.removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $this.attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $this.attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

/**
 * Processes the search input value by trimming any leading/trailing whitespace
 * and replacing any Unicode characters using the `replaceUnicodeChars` function.
 * If the search value is not empty, the modified value is set back to the input field.
 * @param {jQuery} $searchInput - The jQuery object representing the search input field.
 * The function expects an input field where the user enters a search term.
 * @returns {void} This function does not return anything. It directly modifies the input field's value.
 */
function processSearchInput($searchInput) {
    var searchValue = $searchInput.val();
    if (searchValue.length > 0) {
        searchValue = searchValue.trim();
        var searchTerm = replaceUnicodeChars(searchValue);
        $searchInput.val(searchTerm);
    }
}

module.exports = function () {
    $('form[name="simpleSearch"]').submit(function (e) {
        var searchValue = '';
        // If the viewport matches the media query below use the mobile search input
        // 991px is the max width for the mobile search input form
        const isMobile = measures.isMobile();

        if (isMobile) {
            var $searchInputMobile = $('.search-mobile form[name="simpleSearch"] :input[name="q"]');
            processSearchInput($searchInputMobile);
            searchValue = $searchInputMobile.val();
        } else {
            var $searchInputDesktop = $('.search form[name="simpleSearch"] :input[name="q"]');
            processSearchInput($searchInputDesktop);
            searchValue = $searchInputDesktop.val();
        }

        if (!searchValue) {
            e.preventDefault();
        }

        if (!ajaxProgress) {
            var isMobileTextboxEmpty = !!(typeof ($('.search-mobile .search-field').val()) !== 'undefined' && $('.search-mobile .search-field').val().length === 0);
            var isDesktopTextboxEmpty = !!(typeof ($('.navbar-header .search-field').val()) !== 'undefined' && $('.navbar-header .search-field').val().length === 0);
            if (isMobileTextboxEmpty || isDesktopTextboxEmpty) {
                ajaxProgress = false;
            } else {
                ajaxProgress = true;
            }
        }
        var suggestionsList = $('.suggestions .item').filter('.selected');
        if (suggestionsList.length !== 0) {
            e.preventDefault();
            suggestionsList.find('a')[0].click();
        }
    });

    $('input.search-field').each(function () {
        getSuggestionsWrapper(this).show();
        const $header = $('#header');
        var eventType = '';
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        if (!isMobileSearch(this)) {
            eventType = 'focus';
        }
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup ' + eventType, function (e) {
            ajaxProgress = false;
            $header.addClass('open-search');
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });

        $(this).on('paste', function () {
            $(this).trigger('keyup');
        });
    });

    $('button.search-button').on('click', function () {
        if ($(this).closest('form').find('input.search-field').length > 0 && $(this).closest('form').find('input.search-field').val().length === 0) {
            $('input.search-field').focus();
        }
    });

    $('body').on('click focusout', function (e) {
        var $searchContainer = $('.search');
        var $suggestions = $('.site-search .suggestions');
        if (!isMobileSearch(e.target) && ($suggestions.find('a, button').last()[0] === e.target || !$searchContainer.has(e.target).length)) {
            $suggestions.hide();
            $(document).find('#maincontent .seach-modal-bg').detach();
        }
    });

    $('.site-search .reset-button').on('click', function () {
        var $this = $(this);
        $this.removeClass('d-block');
        $('input.search-field').val('');
        $('input.search-field').attr('value', '');
        setTimeout(function () {
            $this.closest('form').find('.search-field').focus();
        }, 500);
    });

    $(document).on('click touchstart', '.search-mobile .close', function () {
        ajaxProgress = true;
        $('#header').removeClass('open-search');
        clearModals();
        $('.suggestions').hide();
        $(document).find('#maincontent .seach-modal-bg').detach();
        $('.search-mobile').removeClass('search-active');
    });

    $(window).resize(function () {
        smallDesktop();
    });

    $(document).on('click', '.mobile-search-icon', function (e) {
        e.preventDefault();
        $('body').addClass('search-visible');
        $('.site-search').removeClass('d-none').addClass('d-flex');
        $('.search-mobile').addClass('search-active');
        $('.search-mobile .search-field').focus();
        ajaxProgress = false;
        getSuggestions($('.search-mobile.search-active .search-field'));
    });

    $(document).on('click touchstart', '.null-search-box', function (e) {
        const isMobile = measures.isMobile();
        if (isMobile) {
            e.preventDefault();
            $('body').addClass('search-visible');
            $('.site-search').removeClass('d-none').addClass('d-flex');
            $('.search-mobile').addClass('search-active');
            $('.search-mobile .search-field').focus();
        }
    });

    $(document).on('click touchstart', '.search-mobile .close', function (e) {
        e.preventDefault();
        $('.site-search').removeClass('d-flex').addClass('d-none');
    });

    $('body').on('click focusout', function (e) {
        var $searchContainer = $('.null-search-box');
        var $suggestions = $('.null-search-box .suggestions');
        if (!isMobileSearch(e.target) && ($suggestions.find('a, button').last()[0] === e.target || !$searchContainer.has(e.target).length)) {
            $suggestions.hide();
        }
    });
};
